import React from 'react';
import { Button, Card } from 'semantic-ui-react';

const OtherLoginMethods = () => {
  return (
    <Card.Description
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '5px',
        marginTop: '5px'
      }}
    >
      <Button content="Microsoft" icon="microsoft" />
      <Button content="Google" icon="google" />
    </Card.Description>
  );
};

export default OtherLoginMethods;
