import React, { useState } from 'react';
import { Container, Card, Image, Divider, Dimmer, Loader } from 'semantic-ui-react';
import LoginForm from './Components/LoginForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import OtherLoginMethods from './Components/OtherLoginMethods';

const Login = () => {
  const [loading, setLoading] = useState(false);

  const loadingHandler = () => {
    setLoading((state) => !state);
  };

  return (
    <Container
      fluid
      style={{
        display: 'flex',
        minHeight: '100vh',
        minWidth: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f3f4f5'
      }}
    >
      {!loading ? (
        <Card centered>
          <Card.Content>
            {/* <Card.Header textAlign="center">Login</Card.Header> */}
            <Card.Description
              textAlign="center"
              style={{
                marginBottom: '30px',
                marginTop: '10px'
              }}
            >
              <Image src="/Omniflow_logo_horizontal-225x38.png" alt="Logo Image" />
            </Card.Description>
            <LoginForm loadingHandler={loadingHandler} />
            <Divider />
            <OtherLoginMethods />
          </Card.Content>
        </Card>
      ) : (
        <Dimmer active>
          <Loader size="medium">Authenticating...</Loader>
        </Dimmer>
      )}
    </Container>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default App;
