import React, { useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useLocation } from 'react-router-dom';

const LoginForm = ({ loadingHandler }: { loadingHandler: () => void }) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const location = useLocation();

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const redirectUri = useQuery().get('redirecturi');

  const handleSubmit = async () => {
    loadingHandler();
    var body = { Username: username, Password: password, RedirectUri: redirectUri };
    var reqHeader = new Headers();
    reqHeader.append('Content-Type', 'application/json');
    var requestOptions: RequestInit = {
      method: 'POST',
      headers: reqHeader,
      redirect: 'follow',
      // mode: 'cors',
      body: JSON.stringify(body)
      // credentials: 'include'
    };
    const response = await fetch('/api/v1/auth/login', requestOptions);
    const url = await response.text();

    if (url !== null && url !== undefined) {
      window.location.href = url;
    } else {
      loadingHandler(); //incorrect login credentials -> url not provided, turn off loader
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Input
        icon="user"
        iconPosition="left"
        label="Username"
        value={username}
        onChange={(e, { value }) => setUsername(value)}
      />
      <Form.Input
        icon="lock"
        iconPosition="left"
        label="Password"
        type="password"
        value={password}
        onChange={(e, { value }) => setPassword(value)}
      />
      <Button primary fluid type="submit">
        Login
      </Button>
    </Form>
  );
};

export default LoginForm;
